<template>
    <div>
        <!--------------------面包屑导航-------------------->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item >首页</el-breadcrumb-item>
            <el-breadcrumb-item>运营</el-breadcrumb-item>
            <el-breadcrumb-item>优惠券</el-breadcrumb-item>
        </el-breadcrumb>
        <!----------------------卡片区域---------------------->
        <el-card>
            <div class="search-box">
                <el-button type="primary" size="small" @click="addDialog = true" style="margin-left: 10px;">添加优惠券</el-button>
            </div>
            <!--------------------表格--------------------->
            <el-table :data="list" border v-loading="loading" element-loading-text="加载中,请稍候" :header-cell-style="{background:'#FAFAFA'}"><!--stripe带斑马纹的表格 border边框-->
                <el-table-column type="index" label="序号" width="60px">
                    <template slot-scope="scope">
                        <span>{{ scope.$index + 1 + query.number * (query.page - 1) }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="优惠券名称"></el-table-column>
                <el-table-column prop="type" label="优惠类型">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type == 1">注册惠新</span>
                        <span v-if="scope.row.type == 2">指定用户</span>
                    </template>
                </el-table-column>
                <el-table-column prop="threshold" label="使用门槛">
                    <template slot-scope="scope">
                        <span>{{(Number(scope.row.threshold) / 100).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="amount" label="满减金额">
                    <template slot-scope="scope">
                        <span>{{(Number(scope.row.amount) / 100).toFixed(2)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="validity_days" label="有效天数"></el-table-column>
                <el-table-column prop="time" label="有效期" width="340">
                    <template slot-scope="scope">
                        <span>{{ scope.row.validity_start + '至' + scope.row.validity_end }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="status" label="状态">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status == 0">未生效</span>
                        <span v-if="scope.row.status == 1">已生效</span>
                        <span v-if="scope.row.status == 2">已过期</span>
                    </template>
                </el-table-column>
                <el-table-column prop="" label="操作" width="150">
                    <template slot-scope="scope" >
                        <el-button type="text" size="mini" @click="editItem(scope.row)">编辑</el-button>
                        <el-divider direction="vertical"></el-divider>
                        <el-button type="text" size="mini" @click="showSend(scope.row)" v-if="scope.row.type == 2 && scope.row.status == 1">发放</el-button>
                        <el-divider direction="vertical" v-if="scope.row.type == 2 && scope.row.status == 1"></el-divider>
                        <el-button type="text" size="small" @click="removeItem(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <!--------------------分页组件--------------------->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                           :current-page="query.page" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="query.number"
                           layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
            <!--编辑对话框-->
            <el-dialog :title="isEdit ?  '编辑优惠券' : '添加优惠券'" :visible.sync="addDialog" width="592px"  append-to-body  @close="closeDialog">
                <div class="dialog-content" style="max-height: 530px;overflow-y: auto;">
                    <el-form :model="form" :rules="rules" ref="ruleForm" label-width="150px">
                        <el-form-item label="优惠券名称：" prop="name">
                            <el-input size="small" v-model="form.name" placeholder="请输入" ></el-input>
                        </el-form-item>
                        <el-form-item label="优惠类型：" prop="type">
                            <el-radio-group v-model="form.type" @change="changeType()">
                                <el-radio :label="1">注册惠新</el-radio>
                                <el-radio  :label="2">指定用户</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="使用门槛：" prop="threshold">
                            <el-input size="small" placeholder="请输入" v-model="form.threshold">
                                <template slot="prepend">满</template>
                                <template slot="append">元可用</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="满减金额：" prop="amount">
                            <el-input  size="small" placeholder="请输入" v-model="form.amount">
                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                        <el-form-item label="有效天数：" prop="validity_days" v-if="form.type == 1">
                            <el-input size="small" v-model="form.validity_days" placeholder="请输入" ></el-input>
                        </el-form-item>
                        <el-form-item label="有效期：" prop="time">
                            <el-date-picker size="small" v-model="form.time" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
                                             format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" style="width: 380px;" :default-time="['00:00:00', '23:59:59']"
                                            @input="changeDate">
                            </el-date-picker>
                        </el-form-item>
                    </el-form>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="addDialog = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="submitForm('ruleForm')" :loading="btnLoading">保 存</el-button>
                </span>
            </el-dialog>

            <!--发放对话框-->
            <el-dialog :title="'发放优惠券-' + currentObj.name" :visible.sync="sendDialog" width="1000px"  append-to-body @close="closeSendDialog">
                <div style="height: 490px;">
                    <el-button type="primary" size="small" @click="submitSendForm()">发放优惠券</el-button>
                    <el-table :data="userList" border :header-cell-style="{background:'#FAFAFA'}" @selection-change="handleSelectionChange" height="465">
                        <el-table-column type="selection" :selectable="checkSelectable"  width="55"></el-table-column>
                        <el-table-column type="index" label="序号" width="60px"></el-table-column>
                        <el-table-column prop="avatar" label="头像">
                            <template slot-scope="scope">
                                <div>
                                    <div class="photo" v-if="scope.row.avatar != null">
                                        <el-image :src="scope.row.avatar" style="width: 112px;height: 80px;object-fit: cover;" :preview-src-list="[scope.row.avatar]">
                                        </el-image>
                                    </div>
                                    <div v-else>
                                        <img src="../../assets/gzt_nav_head.png" style="width: 80px;height: 80px;object-fit: cover;"/>
                                    </div>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="nick_name" label="昵称">
                            <template slot-scope="scope">
                                <span>{{ scope.row.nick_name ? scope.row.nick_name : '微信用户'}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="cell_phone" label="手机号"></el-table-column>
                    </el-table>
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button size="small" @click="sendDialog = false">取 消</el-button>
                    <el-button size="small" type="primary" @click="sendDialog = false">关 闭</el-button>
                </span>
            </el-dialog>
        </el-card>
    </div>
</template>

<script>
import _ from "lodash";
export default {
    name: "list",
    data() {
        //校验价格
        var checkPrice = function (rule, value, callback) {
            if (value === '') {
                callback(new Error('请输入'))
            } else if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(value)) {
                callback(new Error('金额格式错误'))
            } else {
                callback()
            }
        }
        return {
            list: [],//表格列表
            total: 0,//总条数
            query: {
                page: 1,//页码
                number: 10,//数量
            },
            loading: false,//表格加载loading
            isEdit: false,//是否编辑
            addDialog: false,
            form: {//表单提交内容
                name: '',
                type: 1,
                threshold: '',
                amount: '',
                validity_days: null,
                time: [],
            },
            rules: {// 表单校验规则对象
                name: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
                type: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
                threshold: [
                    {required:true, validator: checkPrice, trigger:'blur'},
                ],
                amount: [
                    {required:true, validator: checkPrice,trigger:'blur'},
                ],
                validity_days: [
                    {required: true, message: '请输入', trigger: 'blur'},
                ],
                time: [
                    {required: true, message: '请选择', trigger: 'change'},
                ],
            },
            btnLoading: false,//按钮状态
            currentObj: {},//当前对象
            userList: [],//用户列表
            sendDialog: false,//发放弹窗
            selectedData: []//表格勾选
        }
    },
    mounted () {
        this.getList()//查列表
    },
    methods: {
        //从第一页开始重新加载列表
        searchList() {
            this.query.page = 1
            this.query.number = 10
            this.getList()
        },
        //查列表
        async getList() {
            this.loading = true;
            let data = {
                page: this.query.page,
                number: this.query.number,
            }
            let { data: res } = await this.$http.get('/admin/api/coupon/pageList', { params: data})
            this.loading = false;
            if (res.code == 200) {
                if(res.data.result) {
                    this.list = res.data.result
                    this.total = res.data.total
                }
            } else {
                this.$message.error(res.message)
            }
        },
        // 监听每页显示多少条的改变
        handleSizeChange (newSize) {
            this.query.page = 1
            this.query.number = newSize
            this.getList()
        },
        // 监听选择第了几页
        handleCurrentChange (newPage) {
            this.query.page = newPage
            this.getList()
        },
        //切换优惠类型
        changeType() {
            this.form.validity_days = ''
        },
        //添加/编辑
        submitForm(formName) {
            this.$refs[formName].validate(async (valid) => {
                if (valid) {
                    let thresholdCopy = _.cloneDeep(this.form.threshold)//使用门槛
                    let amountCopy = _.cloneDeep(this.form.amount)//满减金额
                    let threshold = parseInt((thresholdCopy * 100).toFixed(0));
                    let amount = parseInt((amountCopy * 100).toFixed(0));
                    if( parseInt((threshold * 0.2).toFixed(0)) < amount) {
                        return this.$message.error('抵扣金额不能大于满减金额的20%')
                    }
                    this.btnLoading = true;
                    let name = this.form.name.trim();
                    if(!name) {
                        return this.$message.error('请输入优惠券名称')
                    }
                    let param = {
                        name: name,
                        type: this.form.type,
                        threshold: threshold,
                        amount: amount,
                        validity_days: this.form.validity_days,
                        validity_start: this.form.time[0],
                        validity_end: this.form.time[1],
                        discount_type: 1
                    }
                    let res = {};
                    if (this.isEdit) {
                        param.id = this.form.id;
                        res = await this.$http.post('/admin/api/coupon/update', param)
                    } else {
                        res = await this.$http.post('/admin/api/coupon/add', param)
                    }
                    if (res.data.code === 200) {
                        this.btnLoading = false;
                        this.$message.success('操作成功！')
                        this.closeDialog();
                        await this.searchList();
                    } else {
                        this.btnLoading = false;
                        this.$message.error(res.data.message)
                    }
                } else {
                    return false;
                }
            });
        },
        //关闭对话框
        closeDialog() {
            if (this.form.id) {
                delete this.form.id;
            }
            this.btnLoading = false;
            this.$refs['ruleForm'].resetFields();
            this.addDialog = false;
            this.isEdit = false;
        },
        //编辑
        async editItem(rows) {
            this.isEdit = true;
            this.$nextTick(() => {
                this.form = _.cloneDeep(rows);
                this.form.threshold =  Number(rows.threshold) / 100;
                this.form.amount =  Number(rows.amount) / 100;
                this.form.time = [rows.validity_start, rows.validity_end]
            })
            this.addDialog = true; //打开对话框
        },
        //有效期输入更新页面显示
        changeDate() {
            this.$forceUpdate()
        },
        //删除
        removeItem(rows) {
            // this.$confirm('此操作将永久删除', '确认要删除“' + rows.name + '”吗？', {
            this.$confirm('此操作将永久删除', '确认要删除吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                iconClass: 'el-icon-warning',
                closeOnClickModal: false,
                center: true,
                showClose: false,
                beforeClose: async (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        let res = await this.$http.post('/admin/api/coupon/delete', {id: rows.id})
                        if (res.data.code === 200) {
                            done();
                            this.$message.success('操作成功！')
                            await this.searchList();
                        } else {
                            this.$message.error(res.data.message)
                        }
                        instance.confirmButtonLoading = false;
                    } else {
                        done();
                    }
                }
            }).catch(() => {
                this.$message.info('已取消');
            });
        },
        //打开发放优惠券
        async showSend(rows) {
            this.currentObj = rows
            let res = await this.$http.get('/admin/api/coupon/getUserList', { params: {coupon_id: rows.id}})
            if (res.data.code == 200) {
                if(res.data.data) {
                    this.userList = res.data.data
                    this.sendDialog = true
                }
            } else {
                this.$message.error(res.data.message)
            }
        },
        //勾选
        handleSelectionChange(val) {
            this.selectedData = val;
        },
        //禁用勾选
        checkSelectable(row, index) {
            if (row.coupon) {
                return false;
            } else {
                return true;
            }
        },
        //提交发放优惠券
        async submitSendForm() {
            if(this.selectedData.length == 0) {
                return this.$message.error('请先勾选要发放优惠券的用户')
            }
            this.$confirm('', '确认要给这' + this.selectedData.length + '位用户发放此优惠券吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                iconClass: 'el-icon-warning',
                closeOnClickModal: false,
                center: true,
                showClose: false,
                beforeClose: async (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        let ids = [];
                        for(let i=0; i<this.selectedData.length; i++) {
                            ids.push(this.selectedData[i].id)
                        }
                        let data = {
                            user_ids: ids,
                            coupon_id: this.currentObj.id,
                        }
                        let res = await this.$http.post('/admin/api/coupon/addUserCoupon', data)
                        if (res.data.code === 200) {
                            done();
                            this.$message.success('操作成功！')
                            this.closeSendDialog()
                            await this.getList();
                        } else {
                            this.$message.error(res.data.message)
                        }
                        instance.confirmButtonLoading = false;

                    } else {
                        done();
                    }
                }
            }).catch(() => {
                this.$message.info('已取消');
            });
        },
        //关闭发放弹窗
        closeSendDialog() {
            this.selectedData = []
            this.userList = []
            this.sendDialog = false;
        },
    }
}
</script>

<style scoped>

</style>
<style>
.photo .el-image .el-image__inner {width: 100%;height: 100%;object-fit: cover;}
.el-image-viewer__wrapper {z-index: 3000 !important;}
.el-image img {object-fit: cover;}
</style>
